import React from "react"
import { Row, Col, Image, Stack } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {  faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

import profile from '../assets/pictures/profile2.jpg'

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../stylesheet.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="jumbotron" style={{margin:"50px 50px"}}>
      <Row className="justify-content-md-center">
        <Col lg={6} style={{marginTop: "15%"}}>
          <p className="subtext">
            <span style={{fontSize: "1.5em", letterSpacing: "-2px", fontStretch: "ultra-condensed"}}>Hello, I'm Priscilla Hamiter </span><br/>I'm interested in front-end development and programming interactive data visualizations. Right now I'm focusing on starting a new career, learning new JavaScript frameworks, building my coding portfolio, and documenting personal art projects.
          </p>
        </Col>
        <Col md="auto">
          <Image src={profile} width="450px" height="450px" fluid roundedCircle/>
        </Col>
        <Col xs="auto">
          <Stack gap={4} style={{marginTop: "150px"}}>
            <a href={"https://www.linkedin.com/in/priscilla-hamiter-668293124/"} className={"link"}>
              <FontAwesomeIcon icon={faLinkedin} style={{height: "2em", width: "auto"}}/>
            </a>
            <a href={"https://github.com/phamiter253"} className={"link"}>
              <FontAwesomeIcon icon={faGithub} style={{height: "2em", width: "auto"}}/>
            </a>
            <a href={"mailto:priscilla.hamiter@gmail.com"} className={"link"}>
            <FontAwesomeIcon icon={faEnvelope} style={{height: "2em", width: "auto"}}/>
            </a>
          </Stack>
        </Col>
      </Row>
    </div>
  </Layout>
)

export default IndexPage
